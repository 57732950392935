import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import qs from "qs";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { Footer } from "../components/sections/footer";
import {
  OpenCorrectZorbiLink,
  useCanUseExtension,
  useDetectBrowser,
} from "../utils";
import { Formik, Field, Form } from "formik";
import { TrackingAPI } from "../utils/trackingapi";

import SEO from "../components/common/layout/seo";

const DeletePage = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const canUseExtension = useCanUseExtension();
  const browser = useDetectBrowser();
  const [userId] = useState(queryParams.userId);

  useEffect(() => {
    if (userId) {
      TrackingAPI.trackUninstall(userId);
    }
  }, []);

  return (
    <Layout>
      <SEO title="Delete your Zorbi Account" />
      <Navigation />
      <section className="py-20">
        <div className="container px-4 mx-auto">
          <div className="max-w-2xl mx-auto text-center">
            <div className="max-w-md mx-auto mb-8">
              <h2 className="mb-2 text-4xl font-bold font-heading">
                Delete your account
              </h2>
            </div>
            <div>
              <p>
                Zorbi stores data on your flashcards, how you're using the
                platform, and study progress data to enhance your learning
                experience.
                <br /> <br />
                Your flashcard data is retained on our servers as long as you're
                using the app.
                <br />
                <br /> If you choose to delete your Zorbi account, all
                associated user-generated data will be permanently deleted from
                our servers.
                <br />
                <br />
                Contact us at{" "}
                <a href="mailto:contact@zorbiapp.com">
                  contact@zorbiapp.com
                </a>{" "}
                to request a deletion of your account
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-20 pb-24 bg-purple-600">
        <div className="max-w-xl mx-auto text-center">
          <span className="inline-block px-3 py-1 text-xs font-semibold text-white bg-purple-500 rounded-xl">
            Made a mistake?
          </span>
          <h2 className="mt-3 text-3xl font-bold text-white md:text-4xl font-heading">
            No worries. Come back to Zorbi again for free.
          </h2>
          <div className="mt-6">
            <a
              className="inline-block px-8 py-4 text-xs font-semibold leading-none text-blue-600 transition duration-300 bg-white border rounded hover:bg-blue-600 hover:text-white hover:border-white"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                OpenCorrectZorbiLink();
              }}
            >
              {canUseExtension
                ? browser && browser.name.includes("edge")
                  ? "Add to Edge"
                  : "Add to Chrome"
                : "Try Zorbi"}
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default DeletePage;
